<template>
  <c-box flex-grow="1">
    <BreadcrumbPath
      px="0"
      pb="20px"
      :paths="[
        { label: 'Managemen Ahli Gizi', href: '/admin/nutritionists/' },
        { label: 'Detail Ahli Gizi', isCurrent: true },
      ]"
    />
    <!-- <c-divider my="16px" /> -->
    <c-box
      position="relative"
      mx="auto"
      :mb="['0', '16px']"
      :p="['1rem', '2rem 4rem']"
      border="1px solid #f2f2f2"
      :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
      :border-radius="['0px', '16px']"
      width="100%"
    >
      <c-flex
        justify="space-between"
        align="center"
        mb="30px"
      >
        <c-heading
          as="h3"
          font-family="Montserrat"
          font-weight="bold"
          font-size="24px"
          line-height="36px"
        >
          Detail Ahli Gizi
        </c-heading>
        <c-box>
          <!-- <c-button
            variant="solid"
            variant-color="red"
            border-radius="60px"
            min-w="114px"
            h="auto"
            py="13px"
            px="18px"
            mr="20px"
            font-size="16px"
            font-weight="500"
            line-height="24px"
            @click="isOpen = true"
          >
            <c-image
              :src="require('@/assets/icon-trash.svg')"
              alt="file program"
              mr="10px"
              border-radius="6px"
            />
            Hapus
          </c-button> -->
          <c-button
            variant="solid"
            variant-color="primary"
            border-radius="60px"
            h="auto"
            min-w="114px"
            py="13px"
            px="18px"
            font-size="16px"
            font-weight="500"
            line-height="24px"
            as="router-link"
            :to="{
              name: 'admin.nutritionists.edit',
              params: { id: id },
            }"
          >
            <c-box mr="10px">
              <inline-svg
                :src="require('@/assets/icons/icon-edit.svg')"
                height="20px"
                width="20px"
                fill="#FFF"
              />
            </c-box>
            Edit
          </c-button>
        </c-box>
      </c-flex>

      <c-heading
        as="h4"
        font-family="Montserrat"
        font-weight="bold"
        font-size="18px"
        line-height="27px"
        color="#008C81"
      >
        Data Pribadi
      </c-heading>
      <c-divider
        mt="10px"
        mb="22px"
        color="#888"
      />

      <c-box mb="43px">
        <c-form-control mb="30px">
          <c-text
            mb="6px"
            color="primary.400"
            font-weight="500"
            font-size="16px"
            line-height="24px"
          >
            Foto Ahli Gizi
          </c-text>
          <c-image
            :src="getPhotoUser(item.photoUrl)"
            w="150px"
            h="150px"
            alt="avatar"
            border-radius="50%"
            object-fit="cover"
          />
        </c-form-control>
        <c-form-control mb="30px">
          <c-text
            mb="2px"
            color="primary.400"
            font-weight="500"
            font-size="16px"
            line-height="24px"
          >
            Nomor STR
          </c-text>
          <c-text
            color="superDarkGray.900"
            font-size="18px"
            line-height="27px"
            font-weight="500"
          >
            {{ item.str ?? '-' }}
          </c-text>
        </c-form-control>
        <c-form-control mb="30px">
          <c-text
            mb="2px"
            color="primary.400"
            font-weight="500"
            font-size="16px"
            line-height="24px"
          >
            Email
          </c-text>
          <c-text
            color="superDarkGray.900"
            font-weight="500"
            font-size="18px"
            line-height="27px"
          >
            {{ item.email ?? '-' }}
          </c-text>
        </c-form-control>
        <c-form-control mb="30px">
          <c-text
            mb="2px"
            color="primary.400"
            font-weight="500"
            font-size="16px"
            line-height="24px"
          >
            Nama Depan
          </c-text>
          <c-text
            color="superDarkGray.900"
            font-size="18px"
            line-height="27px"
            font-weight="500"
          >
            {{ item.firstName ?? '-' }}
          </c-text>
        </c-form-control>
        <c-form-control mb="30px">
          <c-text
            mb="2px"
            color="primary.400"
            font-weight="500"
            font-size="16px"
            line-height="24px"
          >
            Nama Belakang
          </c-text>
          <c-text
            color="superDarkGray.900"
            font-size="18px"
            line-height="27px"
            font-weight="500"
          >
            {{ item.lastName ?? '-' }}
          </c-text>
        </c-form-control>
        <c-form-control mb="30px">
          <c-text
            mb="2px"
            color="primary.400"
            font-weight="500"
            font-size="16px"
            line-height="24px"
          >
            Gelar
          </c-text>
          <c-text
            v-for="(edu, index) in item.education"
            :key="'gelar' + index"
            color="superDarkGray.900"
            font-size="18px"
            line-height="27px"
            font-weight="500"
          >
            {{ edu.title ?? '-' }}
          </c-text>
        </c-form-control>
        <c-form-control mb="30px">
          <c-text
            mb="2px"
            color="primary.400"
            font-weight="500"
            font-size="16px"
            line-height="24px"
          >
            Pendidikan
          </c-text>
          <c-list
            style-type="disc"
            color="superDarkGray.900"
            font-size="18px"
            line-height="27px"
            font-weight="500"
            font-family="Roboto"
          >
            <c-list-item
              v-for="(edu, index) in item.education"
              :key="'edu' + index"
              text-transform="capitalize"
            >
              {{ edu.level }}
              {{
                edu.university === "0"
                  ? edu.otherUniversity
                  : getUniversityName(edu.university)
              }}
            </c-list-item>
            <c-list-item v-show="isDataEmpty(item.education)">
              -
            </c-list-item>
          </c-list>
        </c-form-control>
        <c-form-control mb="30px">
          <c-text
            mb="2px"
            color="primary.400"
            font-weight="500"
            font-size="16px"
            line-height="24px"
          >
            Spesialisasi / Keahlian
          </c-text>
          <c-text
            color="superDarkGray.900"
            font-size="18px"
            line-height="27px"
            font-weight="500"
          >
            {{ item.specialization ? (Array.isArray(item.specialization) ? item.specialization.join(', ') : item.specialization) : '-' }}
          </c-text>
        </c-form-control>
        <c-form-control mb="30px">
          <c-text
            mb="2px"
            color="primary.400"
            font-weight="500"
            font-size="16px"
            line-height="24px"
          >
            Bahasa yang dikuasai
          </c-text>
          <c-text
            color="superDarkGray.900"
            font-size="18px"
            line-height="27px"
            font-weight="500"
          >
            {{ item.languages ?? '-' }}
          </c-text>
        </c-form-control>
        <c-form-control mb="30px">
          <c-text
            mb="2px"
            color="primary.400"
            font-weight="500"
            font-size="16px"
            line-height="24px"
          >
            Usia Klien
          </c-text>
          <c-text
            color="superDarkGray.900"
            font-size="18px"
            line-height="27px"
            font-weight="500"
          >
            {{ item.clientAgeHandled ?? '-' }}
          </c-text>
        </c-form-control>
        <c-form-control mb="30px">
          <c-text
            mb="2px"
            color="primary.400"
            font-weight="500"
            font-size="16px"
            line-height="24px"
          >
            Masalah yang dikuasai
          </c-text>
          <c-text
            color="superDarkGray.900"
            font-size="18px"
            line-height="27px"
            font-weight="500"
          >
            {{ item.problemHandled ? (Array.isArray(item.problemHandled) ? item.problemHandled.join(', ') : item.problemHandled) : '-' }}
          </c-text>
        </c-form-control>
        <c-form-control mb="30px">
          <c-text
            mb="2px"
            color="primary.400"
            font-weight="500"
            font-size="16px"
            line-height="24px"
          >
            Jam Layanan
          </c-text>
          <c-text
            color="superDarkGray.900"
            font-size="18px"
            line-height="27px"
            font-weight="500"
          >
            {{ item.serviceHour ? `${item.serviceHour} WIB` : '-' }}
          </c-text>
        </c-form-control>
        <c-form-control mb="30px">
          <c-text
            mb="2px"
            color="primary.400"
            font-weight="500"
            font-size="16px"
            line-height="24px"
          >
            Jam Aktif Chat
          </c-text>
          <c-list
            style-type="disc"
            color="superDarkGray.900"
            font-size="18px"
            line-height="27px"
            font-weight="500"
            font-family="Roboto"
          >
            <c-list-item
              v-for="(active, index) in item.activeHour"
              :key="'active' + index"
            >
              {{ active.startHour }} - {{ active.endHour }} WIB
            </c-list-item>
            <c-list-item v-show="isDataEmpty(item.activeHour)">
              -
            </c-list-item>
          </c-list>
        </c-form-control>
        <c-form-control mb="30px">
          <c-text
            mb="2px"
            color="primary.400"
            font-weight="500"
            font-size="16px"
            line-height="24px"
          >
            Jadwal Layanan
          </c-text>
          <c-list
            style-type="disc"
            color="superDarkGray.900"
            font-size="18px"
            line-height="27px"
            font-weight="500"
            font-family="Roboto"
          >
            <c-list-item
              v-for="(service, index) in item.serviceTime"
              :key="'service'+ index"
            >
              {{ service.title }}: {{ service.value ? service.value : '-' }}
            </c-list-item>
            <c-list-item v-show="isDataEmpty(item.serviceTime)">
              -
            </c-list-item>
          </c-list>
        </c-form-control>
        <c-form-control mb="30px">
          <c-text
            mb="2px"
            color="primary.400"
            font-weight="500"
            font-size="16px"
            line-height="24px"
          >
            Pengalaman Bekerja
          </c-text>
          <c-list
            style-type="disc"
            color="superDarkGray.900"
            font-size="18px"
            line-height="27px"
            font-weight="600"
            font-family="Roboto"
          >
            <c-list-item
              v-for="(work, index) in workExperiences"
              :key="'work' + index"
            >
              <c-box display="inline-table">
                <c-text>
                  {{ work.companyName }}
                </c-text>
                <c-box font-weight="400">
                  {{ work.position }} ({{ work.startYear }} - {{ work.endYear }})
                </c-box>
              </c-box>
            </c-list-item>
            <c-list-item v-show="isDataEmpty(item.workExperiences)">
              -
            </c-list-item>
          </c-list>
        </c-form-control>
        <c-form-control mb="30px">
          <c-text
            mb="2px"
            color="primary.400"
            font-weight="500"
            font-size="16px"
            line-height="24px"
          >
            Kuota
          </c-text>
          <c-text
            color="superDarkGray.900"
            font-size="18px"
            line-height="27px"
            font-weight="500"
          >
            {{ item.quota }}
          </c-text>
        </c-form-control>
        <c-form-control mb="30px">
          <c-text
            mb="2px"
            color="primary.400"
            font-weight="500"
            font-size="16px"
            line-height="24px"
          >
            Rating
          </c-text>
          <c-text
            color="superDarkGray.900"
            font-size="18px"
            line-height="27px"
          >
            <StarRating
              v-model="item.rating"
              :increment="0.5"
              :read-only="true"
              :star-size="26"
              text-class="hidden"
            />
          </c-text>
        </c-form-control>
      </c-box>

      <c-heading
        as="h4"
        font-family="Montserrat"
        font-weight="bold"
        font-size="18px"
        line-height="27px"
        color="#008C81"
      >
        Nama Klien
      </c-heading>
      <c-divider
        mt="10px"
        mb="22px"
        color="#888"
      />

      <c-flex
        v-if="isHasClient"
        justify="space-between"
        align="center"
        gap="1"
        mb="20px"
      >
        <c-input-group
          flex-grow="1"
          size="md"
        >
          <c-input-left-element
            pos="relative"
            style="height: 62px;"
          >
            <c-image 
              :src="require('@/assets/ic-search.svg')" 
              h="24px"
              w="24px"
              pos="absolute"
              top="50%"
              left="15px"
              transform="translateY(-50%)"  
            />
          </c-input-left-element>
          <c-input 
            v-model="search" 
            type="text" 
            placeholder="Cari nama klien"
            bg="none"
            h="62px"
            border-radius="20px"
            pl="50px"
            font-size="18px"
          />
        </c-input-group>
        <c-box pos="relative">
          <c-button
            variant="ghost"
            p="0"
            h="32px"
            w="32px"
            @click="onToggleFilter"
          >
            <inline-svg
              :src="require('@/assets/icons/icon-filter.svg')"
              fill="#888888"
              width="24px"
              height="24px"
            />
          </c-button>
          <c-box 
            v-if="isOpenFilter" 
            v-on-clickaway="onCloseFilter" 
            pos="absolute" 
            top="36px" 
            right="0" 
            w="240px" 
            bg="white" 
            border="1px solid #C4C4C4" 
            rounded="md"
          >
            <c-text
              py="4px"
              text-align="left"
              px="1rem"
              color="#333"
              font-weight="700"
              font-size="16px"
            >
              Status Program
            </c-text>
            <c-box
              v-for="option in filterOptions"
              :key="option.value"
            >
              <c-divider
                border-color="#C4C4C4"
                m="0"
                p="0"
              />
              <c-button
                rounded="0"
                variant="ghost"
                d="flex"
                w="full"
                justify-content="space-between"
                font-weight="500"
                font-size="18px"
                :color="option.value == filter ? '#008C81' : '#888'"
                :bg="option.value == filter ? '#C7F9E3' : '#FFF'"
                h="48px"
                @click="onChangeFilter(option.value)"
              >
                {{ option.label }}
                <c-box
                  v-if="option.value == filter"
                  :height="['14.66px', '21.99px']"
                  :width="['14.66px', '21.99px']"
                  :min-height="['14.66px', '21.99px']"
                  :min-width="['14.66px', '21.99px']"
                >
                  <inline-svg
                    :src="require('@/assets/icons/icon-check.svg')"
                    fill="#008C81"
                  />
                </c-box>
              </c-button>
            </c-box>
          </c-box>
        </c-box>
      </c-flex>

      <c-grid
        v-show="!isLoadingClients"
        w="100%"
        :template-columns="['repeat(1, 1fr)', 'repeat(2, minmax(0, 1fr))']"
        gap="3"
      >
        <CardClient
          v-for="client in clients"
          :key="client.id"
          :item="client"
        />
      </c-grid>

      <SkeletonCardClient v-show="isLoadingClients" />

      <c-flex
        v-if="clients.length == 0"
        justify="center"
        align="center"
        p="30px"
      >
        {{ isHasClient ? 'Klient tidak ditemukan' : 'Tidak punya Klien' }}
      </c-flex>

      <c-flex
        v-if="clients.length > 0"
        justify="space-between"
        py="24px"
      >
        <c-flex align="center">
          <c-text
            color="gray.900"
            font-size="14px"
            line-height="21px"
          >
            Show:
          </c-text>
          <c-select
            v-model="perPage"
            min-w="120px"
            border-radius="6px"
            font-size="14px"
            ml="13px"
            size="md"
            @change="onChangeRow"
          >
            <option value="5">
              5 Rows
            </option>
            <option value="10">
              10 Rows
            </option>
            <option value="15">
              15 Rows
            </option>
            <option value="20">
              20 Rows
            </option>
          </c-select>
        </c-flex>

        <c-flex
          justify-content="flex-end"
          w="100%"
        >
          <c-flex
            justify-content="center"
            align="center"
          >
            <c-button
              p="0"
              mr="16px"
              w="32px"
              h="32px"
              min-w="32px"
              variant="outline"
              border-radius="30px"
              background-color="transparent"
              :is-disabled="currentPage === 1"
              @click="prevPage"
            >
              <c-icon
                name="chevron-left"
                size="24px"
              />
            </c-button>

            <c-button
              v-for="page_ in pages"
              :key="page_"
              v-chakra="{
                ':hover': {
                  bg: 'rgba(0, 140, 129, 0.3)',
                  color: 'primary.400',
                },
              }"
              p="0"
              m="0"
              w="32px"
              h="32px"
              min-w="32px"
              mx="4px"
              border-radius="30px"
              background-color="rgba(0, 140, 129, 0.3)"
              color="primary.400"
              :bg="
                page_ === currentPage
                  ? ['rgba(0, 140, 129, 0.3)']
                  : ['transparent']
              "
              as="router-link"
              :to="{ name: $route.name, query: { ...$route.query, page: page_ } }"
            >
              {{ page_ }}
            </c-button>

            <c-button
              p="0"
              ml="16px"
              w="32px"
              h="32px"
              min-w="32px"
              variant="outline"
              border-radius="30px"
              background-color="transparent"
              :is-disabled="pages.length === page"
              @click="nextPage"
            >
              <c-icon
                name="chevron-right"
                size="24px"
              />
            </c-button>
          </c-flex>
        </c-flex>
      </c-flex>
    </c-box>

    <DeleteConfirm
      :content="deletedText"
      :is-open="isOpen"
      @on-delete="onDelete"
    />
  </c-box>
</template>

<script>
import _ from 'lodash'
import { mapGetters, mapActions } from 'vuex'
import CardClient from './card-client.vue'
import DeleteConfirm from './../delete-confirm.vue'
import StarRating from 'vue-star-rating'
import generalMixin from '@/utils/general-mixins'
import { isDataEmpty } from '@/utils/is-data-empty'
import BreadcrumbPath from '@/components/elements/breadcrumb-path.vue'
import SkeletonCardClient from '@/components/skeletons/skeleton-card-client.vue'
import { mixin as clickaway } from 'vue-clickaway'

export default {
  name: 'ManagementNutritionistsDetail',
  components: { CardClient, DeleteConfirm, StarRating, BreadcrumbPath, SkeletonCardClient },
  mixins: [generalMixin, clickaway],
  data() {
    return {
      isOpen: false,
      isLoadingClients: false,
      isOpenFilter: false,
      search: '',
      filter: '',
      filterOptions: [
        {
          label: 'Semua',
          value: '',
        },
        {
          label: 'Aktif',
          value: 'active',
        },
        {
          label: 'Pending',
          value: 'pending',
        },
        {
          label: 'Selesai',
          value: 'done',
        },
      ],
      isHasClient: false,
      page: 1,
      perPage: '10',
    }
  },
  computed: {
    ...mapGetters({
      item: 'admNutritionists/item',
      clients: 'admNutritionists/clientItems',
      meta: 'admNutritionists/meta',
      universities: 'general/universities',
    }),
    id() {
      return this.$route.params.id
    },
    currentPage() {
      return parseInt(this.$route.query.page ?? 1, 10)
    },
    pages() {
      let total = this.meta.total ?? 1
      let perPage = parseInt(this.perPage) ?? 10
      let length = Math.ceil(total / perPage)
      return Array.from({ length }, (_, idx) => idx + 1)
    },
    deletedText() {
      return `Anda yakin ingin hapus ahli gizi <br /> “${this.item.firstName} ${
        this.item.lastName ? ' ' + this.item.lastName : ''
      }”?`
    },
    workExperiences() {
      if (this.item?.workExperiences) {
        return _.reverse(this.item.workExperiences)
      }
      return [] 
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        this.page = route.query.page ? parseInt(route.query.page) : 1
        let params = new URLSearchParams()
        params.set('page', this.page)
        params.set('perpage', this.perPage)
        params.set('filter', this.filter)
        params.set('q', this.search)
        let data = {
          id: this.id,
          params: params,
        }
        this.getNutritionistsClients(data)
      },
    },
    search: {
      handler: _.debounce(function(value) {
        this.$router.push({
          name: this.$route.name,
          query: { ...this.$route.query, page: 1, search: value },
        })
      }, 500),
    }, 
  },
  async mounted() {
    this.loadUniversities()
    this.detailNutritionists(this.id)
  },
  methods: {
    isDataEmpty,
    ...mapActions({
      detailNutritionists: 'admNutritionists/detailNutritionists',
      nutritionistsClients: 'admNutritionists/nutritionistsClients',
      deleteNutritionists: 'admNutritionists/deleteNutritionists',
      loadUniversities: 'general/loadUniversities',
    }),
    getUniversityName(id) {
      if (this.universities.length === 0) return ''
      const data = this.universities.find((dt) => dt.id.toString() === id)
      if (!_.isEmpty(data)) {
        return data.name
      }
      return ''
    },
    async getNutritionistsClients(data) {
      if (!this.isLoadingClients) {
        this.isLoadingClients = true
        await this.nutritionistsClients(data)
        if (this.clients.length > 0 && !this.isHasClient) {
          this.isHasClient = true
        }
        this.isLoadingClients = false
      }
    },
    onDelete(state) {
      this.isOpen = state
      if (!this.isOpen) return
      this.deleteNutritionists(this.id)
        .then(() => {
          this.$toast({
            title: 'Success',
            description: 'Ahli Gizi has been deleted.',
            status: 'success',
            duration: 5000,
            position: 'bottom-right',
            variant: 'subtle',
          })
          this.$router.push({
            name: 'admin.nutritionists',
          })
        })
        .catch((err) => {
          this.$toast({
            title: 'Failed',
            description: err.data.message ?? 'Ops! Something when wrong.',
            status: 'error',
            duration: 5000,
            position: 'bottom-right',
            variant: 'subtle',
          })
        })
    },
    onToggleFilter() {
      this.isOpenFilter = !this.isOpenFilter
    },
    onCloseFilter() {
      this.isOpenFilter = false
    },
    onChangeFilter(value) {
      this.onCloseFilter()
      this.filter = value
      this.$router.push({
        name: this.$route.name,
        query: { ...this.$route.query, page: 1, filter: value },
        params: { savePosition: true },
      })
    },
    onChangeRow() {
      this.page = 1
      this.$router.push({
        name: this.$route.name,
        query: { ...this.$route.query, page: this.page, perpage: this.perPage },
        params: { savePosition: true },
      }).catch(() => {})
    },
    prevPage() {
      this.page -= 1
      this.$router.push({
        name: this.$route.name,
        query: { ...this.$route.query, page: this.page },
        params: { savePosition: true },
      }).catch(() => {})
    },
    nextPage() {
      this.page += 1
      this.$router.push({
        name: this.$route.name,
        query: { ...this.$route.query, page: this.page },
        params: { savePosition: true },
      }).catch(() => {})
    },
  },
}
</script>
